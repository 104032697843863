import React, { useEffect, useMemo, useRef, useState } from "react";

import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import consoleConfig from "console_config";
import { AddButton } from "ds/Button";
import Billing from "ds/Icons/Billing";
import LoadingIcon from "ds/Icons/Loading";
import Projects from "ds/Icons/Projects";
import Security from "ds/Icons/Security";
import Settings from "ds/Icons/Settings";
import Team from "ds/Icons/Team";
import Tick from "ds/Icons/Tick";
import User from "ds/Icons/User";
import { Label } from "ds/Typography";
import useUrls from "Hooks/useUrls";
import getUrl from "Libs/getUrl";
import { selectCurrentUserIsAdmin } from "Reducers/app/selectors";
import {
  selectFeatureBillingEnabled,
  selectFeatureCreateOrganizationEnabled,
  selectFeatureDisableAllProjectsEnabled,
  selectFeatureUsageBasedBillingEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import {
  organizationByDescriptionIdSelector,
  organizationsMultiVendorOrgsSelector
} from "Reducers/organization";
import { useTrialInfo } from "Reducers/organization/hooks/useTrialnfo";
import { loadingListSelector } from "Reducers/organization/subscription";
import InviteModal from "src/organization/pages/settings/pages/members/components/InviteModal/InviteModal";
import { useIsOrganizationSecurityEnabled } from "src/organization/pages/settings/pages/security/hooks/useIsOrganizationSecurityEnabled";
import { useIsTeamsEnabled } from "src/organization/pages/settings/pages/teams/hooks/useIsTeamsEnabled";
import { useAppSelector } from "Store/hooks";

import * as S from "./OrganizationMenu.styles";

import type { Organization } from "platformsh-client";

type FormattedOrganizationsProps = {
  organizationsList?: Organization[];
  searchInputValue?: string;
};

type OrganizationListItemProps = {
  isActive: boolean;
  onClick: () => void;
  label: string;
  vendor: string;
  showVendor?: boolean;
};

const getFormattedOrganizations = ({
  organizationsList,
  searchInputValue
}: FormattedOrganizationsProps) => {
  return searchInputValue
    ? organizationsList?.filter(organization =>
        organization?.label
          .toLowerCase()
          .trim()
          .includes(searchInputValue?.toLocaleLowerCase().trim())
      )
    : organizationsList;
};

const OrganizationListItem = ({
  isActive,
  onClick,
  label,
  vendor,
  showVendor
}: OrganizationListItemProps) => {
  const theme = useTheme();
  const isLoadingOrganizationSubscriptions =
    useAppSelector(loadingListSelector);

  return (
    <S.OrganizationListItem $isActive={isActive} onClick={onClick}>
      {consoleConfig.CUSTOM_ORGANIZATION_VENDORS && showVendor ? (
        <S.OrganizationListItemContent>
          <S.ListItemIcon
            src={`/logo-${vendor}${theme.name === "dark" ? "-dark" : ""}.svg`}
          />
          <S.OrganizationListItemName>{label}</S.OrganizationListItemName>
        </S.OrganizationListItemContent>
      ) : (
        <S.OrganizationListItemName>{label}</S.OrganizationListItemName>
      )}
      {isActive &&
        (isLoadingOrganizationSubscriptions ? (
          <LoadingIcon />
        ) : (
          <S.OrganizationListTick />
        ))}
    </S.OrganizationListItem>
  );
};

type OrganizationMenuProps = {
  isOpen: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  isInviteModalOpen: boolean;
  setIsInviteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrganizationMenu = ({
  isOpen,
  onOpenChange,
  isInviteModalOpen,
  setIsInviteModalOpen
}: OrganizationMenuProps) => {
  const intl = useIntl();
  const { organizationId } = useParams<{ organizationId: string }>();
  const navigate = useNavigate();

  const { config } = useTrialInfo();
  const organizationTeamsStatus = useIsTeamsEnabled();
  const [isSecurityEnabled] = useIsOrganizationSecurityEnabled(organizationId!);
  const organizationListRef = useRef<HTMLUListElement>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const shouldShowAllProjectsOption = !useAppSelector(
    selectFeatureDisableAllProjectsEnabled
  );

  const createOrganizationEnabled = useAppSelector(
    selectFeatureCreateOrganizationEnabled
  );
  const billingEnabled = useAppSelector(selectFeatureBillingEnabled);
  const organizations = useAppSelector(organizationsMultiVendorOrgsSelector);
  const organizationsList = useMemo(
    () => Object.values(organizations),
    [organizations]
  );

  const [
    createOrganizationUrl,
    organizationSecurityUrl,
    organizationUsersUrl,
    organizationTeamsUrl,
    organizationSettingsUrl
  ] = useUrls(
    [
      "newOrganization",
      "organization.settings.security",
      "organization.settings.users",
      "organization.settings.teams",
      "organization.settings"
    ],
    { organizationId: organizationId ?? "" }
  );

  const formattedOrganizationList = useMemo(
    () =>
      getFormattedOrganizations({
        organizationsList,
        searchInputValue
      }),
    [organizationsList, searchInputValue]
  );
  const isCurrentUserIsAdmin = useAppSelector(selectCurrentUserIsAdmin);
  const isUsageBasedBillingEnabled = useAppSelector(
    selectFeatureUsageBasedBillingEnabled
  );
  const numberOfOrgsBeforeOverflow = shouldShowAllProjectsOption ? 5 : 6;
  const shouldShowSearchInput =
    organizationsList.length >= numberOfOrgsBeforeOverflow;
  const shouldShowShadow =
    formattedOrganizationList &&
    formattedOrganizationList?.length >= numberOfOrgsBeforeOverflow;
  const activeOrganizationIndex = formattedOrganizationList?.findIndex(
    organization => organizationId === organization?.name
  );
  const [isOpenTracker, setIsOpenTracker] = useState(isOpen);
  const selectedOrganization = useAppSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const hasMemberPermission =
    isCurrentUserIsAdmin || !!selectedOrganization?.hasLink("members");

  useEffect(() => {
    if (isOpen && !isOpenTracker && activeOrganizationIndex) {
      organizationListRef?.current?.scrollTo(0, 24 * activeOrganizationIndex);
    }
    setIsOpenTracker(isOpen);
  }, [isOpen, isOpenTracker, activeOrganizationIndex]);

  const onOrganizationClick = (organization: Organization) => {
    const vendorURL =
      consoleConfig.CUSTOM_ORGANIZATION_VENDORS?.[organization.vendor];
    if (vendorURL && vendorURL !== location.origin)
      location.href = `${vendorURL}/${organization.name}`;
    else navigate(`/${organization.name}`);
  };

  const currentVendor = useMemo(
    () =>
      Object.entries(consoleConfig.CUSTOM_ORGANIZATION_VENDORS).find(
        ([, value]) => value === ""
      )?.[0],
    []
  );

  const hasOrganizationsFromMultipleVendors = useMemo(() => {
    if (!consoleConfig.CUSTOM_ORGANIZATION_VENDORS) return false;
    return organizationsList.some(
      organization => organization.vendor !== currentVendor
    );
  }, [organizationsList, currentVendor]);

  if (!isOpen) return null;

  const setSearch = (value: string) => setSearchInputValue(value);

  // for now we check trial but the goal is to check organization status
  const isSuspended = config?.tagText === "suspended";

  return (
    <S.Container>
      <S.LeftSection>
        {shouldShowSearchInput && (
          <S.SearchInput
            id="organizations-search"
            placeholder={intl.formatMessage({ id: "organizations.search" })}
            onChange={setSearch}
            value={searchInputValue}
          />
        )}
        <S.OrganizationListContainer
          ref={organizationListRef}
          isSearchBoxVisible={shouldShowSearchInput}
          shouldOverscroll={shouldShowShadow}
        >
          {shouldShowAllProjectsOption && (
            <S.ListItem
              $isActive={!organizationId}
              onClick={() => navigate("/")}
            >
              <p className="menu-list">
                {intl.formatMessage({ id: "all_projects" })}
              </p>
              {!organizationId && <Tick />}
            </S.ListItem>
          )}
          <Label>{intl.formatMessage({ id: "organizations" })}</Label>
          {formattedOrganizationList?.map(organization => (
            <OrganizationListItem
              key={organization?.label}
              isActive={
                organizationId === organization.name &&
                organization.vendor === currentVendor
              }
              onClick={() => onOrganizationClick(organization)}
              label={organization.label}
              vendor={organization.vendor}
              showVendor={hasOrganizationsFromMultipleVendors}
            />
          ))}
        </S.OrganizationListContainer>
        {createOrganizationEnabled && (
          <S.NewOrganizationButtonContainer shouldShowShadow={shouldShowShadow}>
            <AddButton
              variant="link"
              to={createOrganizationUrl}
              analyticId="organization.new"
            >
              {intl.formatMessage({ id: "organization.new" })}
            </AddButton>
          </S.NewOrganizationButtonContainer>
        )}
      </S.LeftSection>
      <S.RightSection>
        {selectedOrganization?.name ? (
          <>
            <Label>{selectedOrganization.label}</Label>
            <S.Link
              onClick={() => onOpenChange(false)}
              to={`/${organizationId}`}
            >
              <Projects />
              {intl.formatMessage({ id: "projects" })}
            </S.Link>

            <S.Link
              onClick={() => onOpenChange(false)}
              to={organizationSettingsUrl}
            >
              <Settings color="var(--mode-vector-neutral-default)" />
              {intl.formatMessage({ id: "settings" })}
            </S.Link>
            {billingEnabled &&
              (selectedOrganization?.hasLink("orders") ||
                selectedOrganization?.hasLink("estimate-subscription")) && (
                <S.Link
                  onClick={() => onOpenChange(false)}
                  to={getUrl({
                    key: "organization.billing",
                    props: { organizationId: organizationId || "" }
                  })}
                >
                  <Billing />
                  {intl.formatMessage({ id: "menu.billing" })}
                </S.Link>
              )}
            {(isSecurityEnabled || isUsageBasedBillingEnabled) && (
              <S.Link
                onClick={() => onOpenChange(false)}
                to={organizationSecurityUrl}
              >
                <Security />
                {intl.formatMessage({ id: "organizations.security.title" })}
              </S.Link>
            )}
            {["enabled", "disabled-promote-links"].includes(
              organizationTeamsStatus
            ) &&
              hasMemberPermission && (
                <S.Link
                  onClick={() => onOpenChange(false)}
                  to={organizationTeamsUrl}
                >
                  <Team />
                  {intl.formatMessage({ id: "menu.teams" })}
                </S.Link>
              )}
            {!!selectedOrganization?.hasLink("members") && (
              <>
                <S.Link
                  onClick={() => onOpenChange(false)}
                  to={organizationUsersUrl}
                >
                  <User color="var(--mode-vector-neutral-default)" />
                  {intl.formatMessage({ id: "menu.users" })}
                </S.Link>
                {!isSuspended && (
                  <S.InviteUserButtonContainer>
                    <AddButton
                      variant="link"
                      onClick={() => setIsInviteModalOpen(true)}
                      analyticId="menu.organization.invite_user"
                    >
                      {intl.formatMessage({
                        id: "menu.organization.invite_user"
                      })}
                    </AddButton>
                  </S.InviteUserButtonContainer>
                )}
              </>
            )}
          </>
        ) : (
          <Label>
            {intl.formatMessage({
              id: "menu.organization.select_org"
            })}
          </Label>
        )}
      </S.RightSection>
      {isInviteModalOpen && selectedOrganization && (
        <InviteModal
          organizationCapabilities={selectedOrganization.capabilities}
          organizationId={selectedOrganization.id}
          onOpenChange={() => setIsInviteModalOpen(state => !state)}
        />
      )}
    </S.Container>
  );
};

export default OrganizationMenu;
